import type { ReactNode } from 'react'
import Link from 'next/link'

import { toKebabCase } from '@cms/utils/utils'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { useTheme } from '@mui/material/styles'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import RichTextList from '../RichTextList'
import { StyledWrappedLink } from '../StyledWrappedLink'
import Table from '../TextBody/TextBodyTable'
import type { RichTextProps } from './RichText.types'

type ParagraphProps = {
  children?: ReactNode
  dataCy?: string
}

const Paragraph: React.FC<ParagraphProps> = ({ children, dataCy }) => (
  <Typography variant="body2" data-cy={`${dataCy}-rich-text-text`}>
    {children}
  </Typography>
)

const headingsMargin = '16px'
const headingsScrollMarginTop = 'inherit'

const getRichTextRenderOptions = ({ theme, dataCy }) => ({
  renderNode: {
    [BLOCKS.OL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
    [BLOCKS.UL_LIST]: (_node, children) => <RichTextList>{children}</RichTextList>,
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph dataCy={dataCy}>{children}</Paragraph>,
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Typography
        variant="h1"
        data-cy={`${dataCy}-rich-text-headline-one`}
        sx={{
          scrollMarginTop: headingsScrollMarginTop,
          marginBottom: headingsMargin,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography
        id={toKebabCase(node.content[0].value)}
        sx={{
          color: theme.palette.secondary.main,
          scrollMarginTop: headingsScrollMarginTop,
          marginBottom: headingsMargin,
        }}
        variant="h2"
        data-cy={`${dataCy}-rich-text-headline-two`}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography
        id={toKebabCase(node.content?.[0].value)}
        sx={{
          scrollMarginTop: headingsScrollMarginTop,
          marginBottom: headingsMargin,
        }}
        variant="h3"
        data-cy={`${dataCy}-rich-text-sub-headline-three`}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Typography
        variant="h4"
        data-cy={`${dataCy}-rich-text-sub-headline-four`}
        sx={{
          marginBottom: headingsMargin,
          scrollMarginTop: headingsScrollMarginTop,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Typography
        variant="h5"
        data-cy={`${dataCy}-rich-text-sub-headline-five`}
        sx={{
          marginBottom: headingsMargin,
          scrollMarginTop: headingsScrollMarginTop,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (_node, children) => (
      <Typography
        variant="h6"
        data-cy={`${dataCy}-rich-text-sub-headline-six`}
        sx={{
          marginBottom: headingsMargin,
          scrollMarginTop: headingsScrollMarginTop,
        }}
      >
        {children}
      </Typography>
    ),
    [BLOCKS.TABLE]: (_node, children) => (
      <Table dataCy={`${dataCy}-rich-text-table`}>
        <TableBody>{children}</TableBody>
      </Table>
    ),
    [BLOCKS.TABLE_ROW]: (_node, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_CELL]: (_node, children) => <TableCell>{children}</TableCell>,
    [BLOCKS.TABLE_HEADER_CELL]: (_node, children) => (
      <TableCell variant="head" component="th">
        {children}
      </TableCell>
    ),
    [INLINES.ENTRY_HYPERLINK]: ({ data }, children) => {
      return (
        <StyledWrappedLink
          prefetch={false}
          color="primary"
          endIcon=""
          styledLinkReference={data.target}
          analyticsComponentName="content_text"
          nextLinkComponent={Link}
          dataCy={`${dataCy}-rich-text-link`}
          underline="always"
          variant="body2"
          sx={{ padding: 0, lineHeight: '22px' }} // lineHeight should follow paragrapth text one
        >
          {children}
        </StyledWrappedLink>
      )
    },
  },
  preserveWhitespace: true,
})

const RichText: React.FC<RichTextProps> = ({ richTextDocument, dataCy = 'text' }) => {
  const theme = useTheme()
  const richTextRenderOptions = getRichTextRenderOptions({
    theme,
    dataCy,
  })
  return <>{documentToReactComponents(richTextDocument, richTextRenderOptions)}</>
}

export default RichText
