import type { Styles } from '@knauf-group/ct-designs/utils/types'
import type { Theme } from '@mui/material/styles'
import type { SystemStyleObject } from '@mui/system/styleFunctionSx/styleFunctionSx'

export const flexStyles: SystemStyleObject<Theme> = {
  display: 'flex',
  flexDirection: 'column',
}

export const useStyles = (
  theme: Theme,
  bulletColorAndTextOverride: string,
  isTextBold: boolean,
): Styles => {
  return {
    list: {
      p: '0px',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    listItem: {
      p: '0px',
      alignItems: 'start',
    },
    listIcon: {
      minWidth: '16px',
      color: bulletColorAndTextOverride || theme.palette.secondary.main,
      position: 'relative',
      mt: '0',
      height: '22px', // this should follow the line height of the bullet text which is currently 22px as well
      alignItems: 'center',
    },
    icon: {
      // this will render the path tag of the svg icon to desired 8px and also be aligned with ct-designs BulletList
      height: '10.67px',
      width: '10.67px',
    },
    listText: {
      ...flexStyles,
      gap: theme.spacing(2),
      m: '0px',
      whiteSpace: 'pre-line', // this is to ensure breakline is kept as is in Contentful
      color: bulletColorAndTextOverride || theme.palette.text.secondary,
      '& .MuiListItemText-primary': {
        fontWeight: isTextBold ? 700 : 400,
        fontSize: '16px',
      },
      '&:dir(rtl)': {
        flexGrow: 0,
        mr: '8px',
        textAlign: 'start',
      },
    },
  }
}
